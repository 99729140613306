import(/* webpackMode: "eager" */ "/home/forge/vermut-apps-releases/website/16-10-2024-04-05-35/apps/website/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/forge/vermut-apps-releases/website/16-10-2024-04-05-35/apps/website/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/forge/vermut-apps-releases/website/16-10-2024-04-05-35/apps/website/src/assets/logos/vemut_circle-footer.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/forge/vermut-apps-releases/website/16-10-2024-04-05-35/apps/website/src/components/layout/FooterSocialLinks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/forge/vermut-apps-releases/website/16-10-2024-04-05-35/apps/website/src/components/layout/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/forge/vermut-apps-releases/website/16-10-2024-04-05-35/apps/website/src/components/NextImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/forge/vermut-apps-releases/website/16-10-2024-04-05-35/apps/website/src/providers/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/forge/vermut-apps-releases/website/16-10-2024-04-05-35/apps/website/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/forge/vermut-apps-releases/website/16-10-2024-04-05-35/node_modules/next-translate/lib/esm/AppDirI18nProvider.js");
