'use client'
import { Dialog, Transition } from '@headlessui/react'
import cookieCutter from 'js-cookie'
import useTranslation from 'next-translate/useTranslation'
import React, { Fragment, useState } from 'react'

import { useUser } from '@/hooks/useUser'

import Button from '@/components/buttons/Button'
import WebLink from '@/components/links/WebLink'
import NextImage from '@/components/NextImage'

import LogoutImage from '@/assets/auth/logout-man.png'

import { ModalCloseBtn } from '../ImageModal'

function LoginButton() {
  const { t, lang } = useTranslation()
  const { user, logout } = useUser()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLogoutModelOpen, setIsOpen] = useState(false)

  function closeLogoutModal() {
    setIsOpen(false)
  }

  function openLogoutModal() {
    setIsOpen(true)
  }

  async function handleLogout() {
    // utils.track('logout')
    // utils.track('User Logged Out')
    // auth.logout()
    setIsSubmitting(true)

    await logout()

    const location = cookieCutter.get('NEXT_LOCATION')

    // setIsOpen(false)
    window.location.href = `/${lang}/${location}`

    // setIsSubmitting(false)
  }

  return (
    <>
      <div className="hidden my-2 w-full items-center justify-center text-center xl:inline-flex xl:w-auto">
        {user ? (
          <Button
            type="button"
            onClick={openLogoutModal}
            // disabled={disabled || isBusy}
            // isLoading={isBusy}
            size="sm"
            className="w-full xl:w-28 justify-center"
            leftIcon={() => (
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 12.0215C9.58579 12.0215 9.25 12.3573 9.25 12.7715C9.25 13.1857 9.58579 13.5215 10 13.5215L10 12.0215ZM20.2803 13.3018C20.5732 13.0089 20.5732 12.534 20.2803 12.2412L15.5074 7.46818C15.2145 7.17529 14.7396 7.17529 14.4467 7.46818C14.1538 7.76108 14.1538 8.23595 14.4467 8.52884L18.6893 12.7715L14.4467 17.0141C14.1538 17.307 14.1538 17.7819 14.4467 18.0748C14.7396 18.3677 15.2145 18.3677 15.5074 18.0748L20.2803 13.3018ZM10 13.5215L19.75 13.5215L19.75 12.0215L10 12.0215L10 13.5215Z"
                  fill="white"
                />
                <path
                  d="M15.5 5.12443V4.10482C15.5 3.36844 14.903 2.77148 14.1667 2.77148H4.83333C4.09695 2.77148 3.5 3.36844 3.5 4.10482V21.4382C3.5 22.1745 4.09695 22.7715 4.83333 22.7715H14.1667C14.903 22.7715 15.5 22.1745 15.5 21.4382V20.4185"
                  stroke="white"
                  strokeWidth="2"
                />
              </svg>
            )}>
            {t('Salir')}
          </Button>
        ) : (
          <>
            <WebLink
              href={`/${lang}/auth/login`}
              // locale={lang}
            >
              <Button
                type="button"
                // onClick={() => userIntersetsMutation.mutate()}
                // disabled={disabled || isBusy}
                // isLoading={isBusy}
                size="sm"
                className="w-full xl:w-28 justify-center"
                leftIcon={() => (
                  <svg width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx={12.5684} cy={7.71463} r={4} fill="white" stroke="white" />
                    <path
                      d="M20.4631 21.6094C21.6258 21.6094 22.5975 20.6426 22.2197 19.5429C22.1084 19.2188 21.9707 18.8998 21.8072 18.5882C21.3046 17.6303 20.568 16.76 19.6394 16.0269C18.7108 15.2939 17.6084 14.7123 16.3952 14.3156C15.1819 13.9188 13.8816 13.7146 12.5684 13.7146C11.2551 13.7146 9.95478 13.9188 8.74152 14.3156C7.52827 14.7123 6.42588 15.2939 5.49729 16.0269C4.56871 16.76 3.83211 17.6303 3.32956 18.5882C3.16605 18.8998 3.02836 19.2188 2.91699 19.5429C2.53925 20.6426 3.51092 21.6094 4.67362 21.6094L12.5684 21.6094H20.4631Z"
                      fill="white"
                      stroke="white"
                    />
                  </svg>
                )}>
                {t('Entrar')}
              </Button>
            </WebLink>
          </>
        )}
      </div>

      <div className="my-2 w-full items-center justify-center text-center xl:hidden xl:w-auto">
        {user ? (
          <Button
            type="button"
            onClick={openLogoutModal}
            // disabled={disabled || isBusy}
            // isLoading={isBusy}
            // size="sm"
            className="w-full xl:w-28 justify-center"
            leftIcon={() => (
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 12.0215C9.58579 12.0215 9.25 12.3573 9.25 12.7715C9.25 13.1857 9.58579 13.5215 10 13.5215L10 12.0215ZM20.2803 13.3018C20.5732 13.0089 20.5732 12.534 20.2803 12.2412L15.5074 7.46818C15.2145 7.17529 14.7396 7.17529 14.4467 7.46818C14.1538 7.76108 14.1538 8.23595 14.4467 8.52884L18.6893 12.7715L14.4467 17.0141C14.1538 17.307 14.1538 17.7819 14.4467 18.0748C14.7396 18.3677 15.2145 18.3677 15.5074 18.0748L20.2803 13.3018ZM10 13.5215L19.75 13.5215L19.75 12.0215L10 12.0215L10 13.5215Z"
                  fill="white"
                />
                <path
                  d="M15.5 5.12443V4.10482C15.5 3.36844 14.903 2.77148 14.1667 2.77148H4.83333C4.09695 2.77148 3.5 3.36844 3.5 4.10482V21.4382C3.5 22.1745 4.09695 22.7715 4.83333 22.7715H14.1667C14.903 22.7715 15.5 22.1745 15.5 21.4382V20.4185"
                  stroke="white"
                  strokeWidth="2"
                />
              </svg>
            )}>
            {t('Salir')}
          </Button>
        ) : (
          <>
            <WebLink
              href={`/${lang}/auth/login`}
              // locale={lang}
            >
              <Button
                type="button"
                // onClick={() => userIntersetsMutation.mutate()}
                // disabled={disabled || isBusy}
                // isLoading={isBusy}
                // size="sm"
                className="w-full xl:w-28 justify-center"
                leftIcon={() => (
                  <svg width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx={12.5684} cy={7.71463} r={4} fill="white" stroke="white" />
                    <path
                      d="M20.4631 21.6094C21.6258 21.6094 22.5975 20.6426 22.2197 19.5429C22.1084 19.2188 21.9707 18.8998 21.8072 18.5882C21.3046 17.6303 20.568 16.76 19.6394 16.0269C18.7108 15.2939 17.6084 14.7123 16.3952 14.3156C15.1819 13.9188 13.8816 13.7146 12.5684 13.7146C11.2551 13.7146 9.95478 13.9188 8.74152 14.3156C7.52827 14.7123 6.42588 15.2939 5.49729 16.0269C4.56871 16.76 3.83211 17.6303 3.32956 18.5882C3.16605 18.8998 3.02836 19.2188 2.91699 19.5429C2.53925 20.6426 3.51092 21.6094 4.67362 21.6094L12.5684 21.6094H20.4631Z"
                      fill="white"
                      stroke="white"
                    />
                  </svg>
                )}>
                {t('Entrar')}
              </Button>
            </WebLink>
          </>
        )}
      </div>


      <Transition appear show={isLogoutModelOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeLogoutModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="flex w-full max-w-[312px] transform flex-col overflow-hidden rounded-[28px] lg:rounded-[30px] text-left align-middle shadow-xl transition-all lg:max-w-[750px] lg:flex-row">
                  <div className="bg-secondary-50 relative flex w-full items-center justify-center pb-[10px] pt-16 lg:py-20 ">
                    <div className="relative h-36 w-36 lg:h-[250px] lg:w-[250px]">
                      <NextImage
                        layout="fill"
                        objectFit="contain"
                        placeholder="blur"
                        src={LogoutImage}
                        alt="Logout image"
                      />
                    </div>
                  </div>
                  <ModalCloseBtn onClose={closeLogoutModal} className="absolute right-6 top-6 self-end" />
                  <div className="flex flex-col bg-white px-8 lg:w-full lg:px-10 lg:py-20">
                    <div className="mt-2 hidden lg:block">
                      <p className="text-text-800 text-3xl">{t(`Don't Logout!`)}</p>
                    </div>
                    <Dialog.Title
                      as="h3"
                      className=" text-text-800 font-roboto mt-4 text-center text-[28px] font-bold lg:mt-2 lg:text-left lg:text-5xl">
                      {t(`Do you want to leave Vermut?`)}
                    </Dialog.Title>
                    <div className="mb-8 mt-8 flex w-full flex-col justify-between lg:mt-12 lg:mb-0 lg:flex-row">
                      <Button
                        type="button"
                        variant="default"
                        size="base"
                        className="mb-8 w-full justify-center rounded-[20px] border-[1.5px] lg:w-[117px] lg:mb-0"
                        onClick={closeLogoutModal}>
                        No
                      </Button>
                      <Button
                        type="button"
                        variant="primary"
                        size="base"
                        className="w-full justify-center rounded-[20px] border-[1.5px] lg:w-[161px]"
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                        onClick={handleLogout}>
                        {t(`Yes, Logout`)}
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default LoginButton
